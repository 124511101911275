import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Spin, Result, Typography, Row, Col } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import logo from "./../../Images/LOGO-10-black.png";

const { Title } = Typography;

const ValidatePayslip = () => {
	const { code } = useParams();
	const [loading, setLoading] = useState(true);
	const [isValid, setIsValid] = useState(false);
	const [payroll, setPayroll] = useState(null);
	const [error, setError] = useState(null);

	const { apiurl } = useAuth();

	useEffect(() => {
		const validatePayslip = async () => {
			if (code) {
				try {
					setLoading(true);
					const response = await fetch(`${apiurl}/validateqr?code=${code}`);
					if (response.ok) {
						const data = await response.json();
						setIsValid(true);
						setPayroll(data.payroll);
					} else {
						setIsValid(false);
						setError("Invalid payslip.");
					}
				} catch (err) {
					setIsValid(false);
					setError("Error while validating the payslip. Please try again.");
				} finally {
					setLoading(false);
				}
			} else {
				setError("Code is missing from the URL");
				setLoading(false);
			}
		};

		validatePayslip();
	}, [code, apiurl]);

	if (loading) {
		return <Spin tip="Validating..." />;
	}

	return (
		<div style={{ padding: "16px" }}>
			{isValid ? (
				<Result
					icon={
						<CheckCircleOutlined style={{ color: "green", fontSize: "48px" }} />
					}
					title="Payslip is valid"
					subTitle="Here are the details of the payroll:">
					<div>
						<Title level={4}>Employee Payroll Details</Title>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Employee Name:</strong> {payroll.employee.userName}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Organization:</strong> {payroll.parent.orgName}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>Business Unit:</strong>{" "}
									{payroll.child ? payroll.child.name : "N/A"}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Month:</strong> {payroll.month}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>Year:</strong> {payroll.year}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Gross Salary:</strong> {payroll.gross}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>Basic Salary:</strong> {payroll.basic_salary}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Net Salary:</strong> {payroll.net_salary}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>Tax Deduction:</strong> {payroll.tax_deduction}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Loss of Pay (LOP):</strong> {payroll.lop}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>Number of Leaves:</strong> {payroll.no_leaves}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>ESI Deduction:</strong> {payroll.esi_deduction}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>Employer ESI:</strong> {payroll.employer_esi}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={12}>
								<p>
									<strong>Employer PF:</strong> {payroll.employer_pf}
								</p>
							</Col>
							<Col xs={24} sm={12}>
								<p>
									<strong>PF Deduction:</strong> {payroll.pf_deduction}
								</p>
							</Col>
						</Row>
						<Row gutter={[16, 16]}>
							<Col span={24}>
								<p>
									<strong>Allowances:</strong>{" "}
									{JSON.stringify(payroll.allowances)}
								</p>
							</Col>
						</Row>
					</div>
				</Result>
			) : (
				<Result
					status="error"
					icon={
						<CloseCircleOutlined style={{ color: "red", fontSize: "48px" }} />
					}
					title="Invalid Payslip"
					subTitle={error}
				/>
			)}
			<div style={{ float: "right", marginTop: "20px" }}>
				<div>Powered By:</div>
				<img src={logo} width={160} alt="Logo" />
			</div>
		</div>
	);
};

export default ValidatePayslip;
